export const elements = {
  search: document.querySelector('#search'),
  searchForm: document.querySelector('#search-form'),
  wrapper: document.querySelector('#search-wrapper'),
  autoInput: document.querySelector('#search-wrapper-autoInput'),
  autoList: document.querySelector('.search-wrapper-ul-auto'),
  geolocate: document.querySelector('#main-geolocate'),
  sFormBtn: document.querySelector('#search-form-btn'),
  mapDiv: document.querySelector('#map'),
  mapDmX: document.querySelector('.map-DmX'),
  formDiv: document.querySelector('#map-formDiv'),
  mapForm: document.querySelector('#map-form')
};