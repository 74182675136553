import {zoom, norgeCoorAll, zoomAll, kartAPI, topoLayer} from '../config';
import * as kartView from '../views/kartView';
import * as searchView from '../views/searchView';
import {elements} from '../views/base';

export default class Kart {
  constructor() {
    this.map;
    this.marker;
    this.currentSave;
    this.latLng;
    this.bbox;

    // styrer om kartet kan klikkes på
    this.isEnabled = true;
  }

  getKart() {
    // initialize the map
    this.map = window.m = L.map('map').setView(norgeCoorAll, zoomAll);
    L.tileLayer(`${kartAPI}{z}/{y}/{x}.png`, {attribution: '<a href="http://www.kartverket.no/">Kartverket</a>'})
       .addTo(this.map);
    
    this.map.on('click', e => {
      if (this.isEnabled) {
        this.setMarker(e.latlng);
      }
    });

    this.map.on('zoomend', () => {
      this.getBboxFromHiddenMap();
    });
  }

  currentPos() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(position => {
        const latLng = [position.coords.latitude, position.coords.longitude];
        this.currentLatLng = latLng;
        this.setMarker(latLng);
      });
    }
  }

  setMarker(latLng) {
    if (!this.marker) {
      this.marker = L.marker(latLng);
    }

    this.map.setView(latLng, zoom, {
      animate: true
    });

    this.marker.setLatLng(latLng).addTo(this.map);

    this.latLng = latLng;
    this.getBboxFromHiddenMap();

    searchView.clearList();
    searchView.clearInput();
    kartView.renderDmX(kartView.latLngToDmX(latLng));
    if (elements.sFormBtn.classList.contains("hide")) kartView.toggleHideBtn();  // for btn Lag nødplakat
  }

  disableMap() {
    this.isEnabled = false;
    this.map.dragging.disable();
    this.map.touchZoom.disable();
    this.map.doubleClickZoom.disable();
    this.map.scrollWheelZoom.disable();
  }

  enableMap() {
    this.isEnabled = true;
    this.map.dragging.enable();
    this.map.touchZoom.enable();
    this.map.doubleClickZoom.enable();
    this.map.scrollWheelZoom.enable();
  }

  // API-et som genererer bildet av kartet krever en bounding box, altså koordinater for alle hjørnene av bildet
  // Heldigvis kan Leaflet gi oss det via kartet
  // Bruker et skjult kart for å få riktig størrelse på kartbildet
  getBboxFromHiddenMap() {
    if (!this.latLng) {
      return;
    }

    if (!this.hiddenmap) {
      this.hiddenmap = window.hm = L.map('hiddenmap');

      // this.bbox settes når hiddenmap har flyttet ferdig ellers blir bbox feil
      this.hiddenmap.on('moveend', () => {
        const bounds = this.hiddenmap.getBounds();
        const bbox = encodeURIComponent(`${bounds.getSouth()},${bounds.getWest()},${bounds.getNorth()},${bounds.getEast()}`);

        this.bbox = bbox;
      });
    }

    this.hiddenmap.setView(this.latLng, this.map.getZoom(), false);
  }
}
