import {elements} from './base';
import formatcoords from 'formatcoords';
import nanomorph from 'nanomorph';
import html from 'nanohtml';

export const latLngToDmX = (latLng) => {
  const DmX = formatcoords(latLng).format('D m X').split(' ');
  const retEn = DmX[2] === 'N' ? 'nord' : 'sør';
  const retTo = DmX[5] === 'E' ? 'øst' : 'vest';
  return {DmX, retEn, retTo};
};

function createDmXHtml({DmX, retEn, retTo}, clazz='') {
  return html`
  <div class="${clazz}">
    <p class="f6">${DmX[0]}° ${parseFloat(DmX[1]).toFixed(2)}' ${retEn} </p>
    <p class="f6">${DmX[3]}° ${parseFloat(DmX[4]).toFixed(2)}' ${retTo}</p>
  </div>
  `;
}

export const renderDmX = ({DmX, retEn, retTo}) => {
  const markupStr = createDmXHtml({DmX, retEn, retTo}, 'map-DmX tc');
  const markup = html`${markupStr}`;
  nanomorph(elements.mapDmX, markup);
};

export const toggleHideBtn = () => elements.sFormBtn.classList.toggle("hide"); // bundle.css, display: none

export const renderForm = (latLng, bbox) => {
  const {DmX, retEn, retTo} = latLngToDmX(latLng);

  const lat = latLng.lat || latLng[0];
  const lng = latLng.lng || latLng[1];

  const markup = html`
  <div id="map-formDiv" class="w-100 absolute z-9999 top-0" style="height:0;">
    <form id="map-form" action="./mail.php" method="post" class="w-100 db bg-secondary white fw5 f5 pa3">
      <div class="mb3">
        <label class="db f5 fw5" for="name">Navngi stedet</label>
        <input id="name" class="input w-100" type="text" name="name" placeholder="Gi navn til kartposisjonene din" autofocus required>
      </div>
      <div class="form-dmx mb3 dn">
        <p class="f6">Min posisjon er:</p>
        ${createDmXHtml(latLngToDmX(latLng), '')}
      </div>
      <div class="mb3">
        <label class="db f5 fw5" for="email">E-post</label>
        <input id="email" class="input w-100" type="email" name="email" placeholder="test@epost.com" required>
      </div>

      <div class="mb3 f6">
        <label class="" for="nyhetsbrev-j">
        <input id="nyhetsbrev-j" class="input-nyhet" type="radio" name="nyhetsbrev" value="J" required>
        Ja takk, jeg er over 18 år og ønsker å motta e-post om Stiftelsen Norsk Luftambulanses livsviktige arbeid </label>
      </div>
      <div class="mb3 f6">
        <label class="" for="nyhetsbrev-n">
        <input id="nyhetsbrev-n" class="input-nyhet" type="radio" name="nyhetsbrev" value="N">
        Nei takk, jeg ønsker ikke å motta e-post om Stiftelsen Norsk Luftambulanses livsviktige arbeid (Du vil allikevel motta din nødplakat)</label>
      </div>

      <input name="lat" value="${lat}" type="hidden" />
      <input name="lng" value="${lng}" type="hidden" />
      <input name="bbox" value="${bbox}" type="hidden" />
      <input name="dmx1" value="${DmX[0]} grader ${parseFloat(DmX[1]).toFixed(2)} minutter ${retEn}" type="hidden" />
      <input name="dmx2" value="${DmX[3]} grader ${parseFloat(DmX[4]).toFixed(2)} minutter ${retTo}" type="hidden" />
      <div class="mb3">
        <input id="btn-send" class="input w-100 primary b--primary hover-bg-primary hover-b-primary hover-white" type="submit" value="SEND MEG NØDPLAKATEN">
        <button id="avbryt" class="bn bg-transparent underline white ml3 pa3 dn" type="button" href="#">Avbryt</button>
      </div>
    </form>
    <button class="arrow-up-white bg-secondary" type="button"><img src="./images/arrow-up-white.svg"></button>
  </div>
  `;

  nanomorph(elements.formDiv, markup);
};
