import {ssrAPI} from '../config';
import uniqid from 'uniqid';
import axios from 'axios';
import queryParser from './queryParser';

export default class Ssr {
  async getResults(query) {
    // fetch(`${searchAPI}?sok=${this.query}`)
    //   .then(resp => resp.json())
    //   .then(json => json.adresser.map(adr => adr.id = uniqid()))
    //   .then(json => this.result = json.adresser)
    //   .catch(error => console.log(error))
    try {
      const res = await axios(`${ssrAPI}?sok=${queryParser(query)}*&fuzzy=false&utkoordsys=4258&treffPerSide=10&side=1`);

      this.result = res.data.navn || []; // Instead of get a single res, it is better to be an attribute inside class

      this.result = this.result.map(adr => {
        const { representasjonspunkt, navneobjekttype, kommuner } = adr;

        return {
            id: uniqid(),
            lon: representasjonspunkt['øst'],
            lat: representasjonspunkt.nord,
            stedsnavn: adr['skrivemåte'],
            navnetype: navneobjekttype,
            kommunenavn: kommuner[0].kommunenavn,
            isSsr: true
        };
      });
    } catch(error) {
      console.log(error);
    }
  };
}
