import {searchAPI} from '../config';
import uniqid from 'uniqid';
import axios from 'axios';
import queryParser from './queryParser';

export default class Search {
  async getResults(query) {
    // fetch(`${searchAPI}?sok=${this.query}`)
    //   .then(resp => resp.json())
    //   .then(json => json.adresser.map(adr => adr.id = uniqid()))
    //   .then(json => this.result = json.adresser)
    //   .catch(error => console.log(error))
    try {
      const res = await axios(`${searchAPI}?sok=${queryParser(query)}&objtype=Vegadresse`);
      this.result = res.data.adresser || []; // Instead of get a single res, it is better to be an attribute inside Search
      if (!Array.isArray(this.result)) { // if this.result is just one record (object), wrapper as an array
        this.result = [this.result];
      }
      this.result.map(adr => adr.id = uniqid());
      //console.log(this.result);
    } catch(error) {
      console.log(error); 
    }
  };
}